import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

import { config } from "./config";

const app = firebase.initializeApp(config);

const db = app.firestore();
const auth = app.auth();

export { db, auth, firebase };
