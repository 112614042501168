import React from "react";

// Libraries
import { Switch, Route, Redirect } from "react-router-dom";

// Variables
import { routes } from "./config";

// Components
import Anonymous from "./anonymous";

function Login() {
    return (
        <Switch>
            <Route exact path={routes.ROOT} component={Anonymous} />

            <Redirect from="*" to={routes.ROOT} />
        </Switch>
    );
}

export default Login;
