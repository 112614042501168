import React from "react";

// Libraries
// import { animated, useSpring, config, interpolate } from "react-spring";
// import { useHover, useDrag } from "react-use-gesture";

function Button({
    className = "",
    type = "button",
    onClick = null,
    text = "",
    children = null,
    disabled = false,
}) {
    // const [{ sx, sy }, set] = useSpring(() => ({
    //     sx: 1,
    //     sy: 1,
    //     config: config.wobbly,
    // }));

    // const bindHover = useHover(({ hovering }) => {
    //     set({
    //         sx: hovering ? 1.1 : 1,
    //         sy: hovering ? 1.05 : 1,
    //     });
    // });

    // const bindTab = useDrag(({ down }) => {
    //     if (down) {
    //         return set({ sx: 0.8, sy: 1 });
    //     }

    //     if (!down) {
    //         return set({ sx: 1, sy: 1 });
    //     }
    // }); //

    return (
        <button
            // {...bindHover()}
            // {...bindTab()}
            className={`btn shadow-xl ${className}`}
            type={type}
            onClick={onClick}
            disabled={disabled}
        >
            <span className="inline fs-xs">{text}</span>
            {children}
            <div
                className={`btn-inner z-index-minus ${
                    disabled && "bg-disabled"
                }`}
                // style={{
                //     transform: interpolate(
                //         [sx, sy],
                //         (sx, sy) => `scale(${sx},${sy})`
                //     ),
                // }}
            ></div>
        </button>
    );
}

export default Button;
