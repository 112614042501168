const getState = (state) => state.session;

export const getAuthenticated = (state) => getState(state).authenticated;
export const getSessionLoaded = (state) => getState(state).loaded;
export const getSession = (state) => getState(state).session;
export const getUid = (state) => getState(state).session.uid;
export const getSessionNotFound = (state) => getState(state).sessionNotFound;

export const getGameCompleted = (state) =>
    getState(state).session.gameCompleted;
export const getFormCompleted = (state) =>
    getState(state).session.formCompleted;

export const getMeta = (state) => getState(state).session.meta;

export const getQuestions = (state) => getState(state).session.questions;
export const getAnswers = (state) => getState(state).session.answers;

// export const getFormCompleted = state => getState(state).session.formCompleted;
// export const getFormCompleted = state => getState(state).session.formCompleted;
