import { db, firebase } from "../firebase";
import { unwrapList } from "../models";

import { setQuestions } from "./slice";
import { setNotification } from "../notifications";
import { messages } from "../messages";

export const questionsOn = () => (dispatch) => {
    return db
        .collection("questions")
        .orderBy("index", "asc")
        .get()
        .then((payload) => dispatch(setQuestions(unwrapList(payload))));
};

export const setAnswer =
    ({ id, type, result = false }) =>
    async (dispatch, getState) => {
        const sessionId = getState().session.session.id;

        const updateQuestion = db
            .collection("sessions")
            .doc(sessionId)
            .collection("responses")
            .doc(id)
            .set({
                result,
                type,
                createdAt: new Date(),
            })
            .catch((error) =>
                dispatch(
                    setNotification({
                        message: messages.ERROR_UPDATE,
                        error,
                    })
                )
            );

        const updateSession = db
            .collection("sessions")
            .doc(sessionId)
            .update({
                completed: firebase.firestore.FieldValue.arrayUnion(id),
                [`answers.${id}`]: result,
            });

        return Promise.all([updateQuestion, updateSession]);
    };

export const setCompleted = () => (dispatch, getState) => {
    const sessionId = getState().session.session.id;

    return db
        .collection("sessions")
        .doc(sessionId)
        .update({
            gameCompleted: true,
            gameCompletedAt: new Date(),
        })
        .then(() =>
            dispatch(
                setNotification({
                    message: messages.GAME_COMPLETED,
                    error: false,
                })
            )
        )
        .catch((error) =>
            dispatch(
                setNotification({
                    message: messages.ERROR_UPDATE,
                    error,
                })
            )
        );
};
