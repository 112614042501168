import { useEffect } from "react";

const ModalEventHandler = ({ toggle }) => {
    useEffect(() => {
        document.addEventListener("keyup", onKeyUp);
        return () => removeEventHandlers();
        // eslint-disable-next-line
    }, []);

    const removeEventHandlers = () => {
        document.removeEventListener("keyup", onKeyUp);
    };

    const onKeyUp = e => {
        if (e.keyCode === 27) {
            removeEventHandlers();
            toggle();
        }
    };

    return null;
};

export default ModalEventHandler;
