import { useEffect } from "react";

// Libraries
import { connect } from "react-redux";

// Core
import { reset } from "../../../core/notifications";

export default connect(null, { reset })(({ reset }) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            reset();
        }, 3000);
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, []);

    return null;
});
