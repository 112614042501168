import React from "react";

// - Components
import { IconError } from "../icons";

function Navigation({ toggle }) {
    return (
        <div className="modal-navigation container flex flex-align flex-space">
            <div></div>
            <button className="flex flex-align z-index c-1" onClick={toggle}>
                <div className="fs-xs">Sluiten (Esc)</div>
                <IconError className="icon icon-sm ml" />
            </button>
        </div>
    );
}

export default Navigation;
