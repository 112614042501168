import React from "react";

// Components
import Modal from "../modal";
import Container from "./container";

function Form({ open, toggle }) {
    return (
        <Modal open={open} toggle={toggle} center>
            <Container toggle={toggle} />
        </Modal>
    );
}

export default Form;
