// Variables
import { types } from "../config";

// Components
import CardRange from "./card-range";
import CardCompetition from "./card-competition";
import CardIntro from "./card-intro";
import CardOpen from "./card-open";

function Card({ style, item, onChange }) {
    if (item.type === types.SLIDER) {
        return <CardRange {...item} style={style} />;
    }

    if (item.type === types.INTRO) {
        return <CardIntro {...item} style={style} />;
    }

    if (item.type === types.OPEN) {
        return <CardOpen {...item} style={style} />;
    }

    if (item.type === types.COMPETITION) {
        return (
            <CardCompetition {...item} style={style} onComplete={onChange} />
        );
    }

    return null;
}

export default Card;
