import React from "react";

// Libraries
import { useSpring, animated, config } from "react-spring";
import { connect } from "react-redux";
import { createSelector } from "reselect";

// Core
import {
    getNotificationOpen,
    getError,
    getMessage,
    reset,
} from "../../../core/notifications";

// Components
import Timer from "./container";
import { IconError, IconCheck } from "../icons";

const Notification = ({ open, error = null, message, reset }) => {
    const props = useSpring({
        config: config.stiff,
        transform: open ? "translate3d(0,0,0)" : "translate3d(0,100%,0)",
    });

    return (
        <animated.div
            style={props}
            className={`notification flex flex-center flex-align ${
                error ? "btn-no" : "btn-yes"
            }`}
            onClick={reset}
        >
            <div className="container-m flex flex-align c-w">
                {error ? (
                    <IconError className="icon icon-sm mr" />
                ) : (
                    <IconCheck className="icon icon-sm mr" />
                )}
                <span className="fs-xs text-wrap">{message}</span>
            </div>
            {open && <Timer />}
        </animated.div>
    );
};

const mapStateToProps = createSelector(
    getNotificationOpen,
    getError,
    getMessage,
    (open, error, message) => ({
        open,
        error,
        message,
    })
);

export default connect(mapStateToProps, { reset })(Notification);
