import React, { useState } from "react";

// Libraries
import InputRange from "react-input-range";

// Components
import Button from "../../components/buttons";

function Slider({ onChange }) {
    const [value, setValue] = useState(5);

    const onChangeComplete = (payload) => setValue(payload);

    const onSubmitNext = () => {
        onChange(value);
        setValue(5);
    };

    return (
        <div className="pt">
            <div className="relative mt-m">
                <div className="label label-min">Oneens</div>
                <InputRange
                    step={1}
                    maxValue={10}
                    minValue={0}
                    value={value}
                    onChange={onChangeComplete}
                />
                <div className="label label-max">Eens</div>
            </div>
            <Button
                text="Volgende"
                className="mt-m full"
                onClick={onSubmitNext}
            ></Button>
        </div>
    );
}

export default Slider;
