import React from "react";

// Components
import Container from "../container";
import Image from "../images/image";
import { IconSpin } from "../icons";

function Loader() {
    return (
        <Container>
            <Image
                width="100%"
                src={`${process.env.PUBLIC_URL}/logo/logo-eurus.jpg`}
            />
            <div className="flex flex-center">
                <h2 className="fs-l mt-m center">
                    loading...
                    <IconSpin className="icon icon-l ml spin" />
                </h2>
            </div>
        </Container>
    );
}

export default Loader;
