import { auth, db } from "../firebase";

import {
    setAuthentication,
    setSession,
    setSessionNotFound,
    reset,
} from "./slice";
import { DocumentListener, Session } from "../models";
import { setNotification } from "../notifications";
import { messages } from "../messages";

const sessionListener = new DocumentListener();

export const setSessionListener = (payload) => (dispatch) => {
    dispatch(setAuthentication());

    const query = db.collection("sessions").doc(payload.uid);

    sessionListener.on(
        query,
        Session,
        dispatch,
        setSession,
        setSessionNotFound
    );
};

export const signOut = () => async (dispatch) => {
    sessionListener.off(); // remove firestore listener
    await auth.signOut(); // remove firebase token
    dispatch(reset()); // clear the user from the state
};

export const signInAnonymously = () => async (dispatch) => {
    // run the firebase function and set the token

    const { user, error } = await auth
        .signInAnonymously()
        .catch((error) => ({ error }));

    if (error) {
        return dispatch(
            setNotification({
                message: messages.ERROR_CREATE_SESSION,
                error,
            })
        );
    }
    // on successfull creation log to the db aswell
    await db.collection("sessions").doc(user.uid).set({
        createdAt: new Date(),
        uid: user.uid,
    });
    // set the listener
    dispatch(setSessionListener(user));
    dispatch(
        setNotification({
            message: messages.SUCCESS_SESSION,
            error: false,
        })
    );
};

export const createMeta = (payload) => (dispatch, getState) => {
    const sessionId = getState().session.session.id;

    return db
        .collection("sessions")
        .doc(sessionId)
        .update({
            ...payload,
            formCompletedAt: new Date(),
            formCompleted: true,
        })
        .then(() =>
            dispatch(
                setNotification({
                    message: "Informatie succesvol verstuurt, Bedankt",
                    error: false,
                })
            )
        )
        .catch((error) =>
            dispatch(
                setNotification({
                    message: messages.ERROR_UPDATE,
                    error,
                })
            )
        );
};

export const createFeedback = ({ email, feedback }) => (dispatch, getState) => {
    const sessionId = getState().session.session.id;
    return db
        .collection("sessions")
        .doc(sessionId)
        .update({
            feedback,
            feedbackEmail: email,
            feedbackCreatedAt: new Date(),
        })
        .then(() =>
            dispatch(
                setNotification({
                    message: "Jouw feedback is succesvol verstuurt",
                    error: false,
                })
            )
        )
        .catch((error) =>
            dispatch(
                setNotification({
                    message:
                        "Er is een fout opgetreden bij het versturen van jouw feedback",
                    error: true,
                })
            )
        );
};
