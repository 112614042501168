import { types } from "../config";

// Components
import Slider from "./slider";
import Button from "../../components/buttons";
import Open from "./open";

function Selectors({ onChange, item = {} }) {
    if (item.type === types.OPEN) {
        return <Open onChange={onChange} />;
    }

    if (item.type === types.SLIDER) {
        return <Slider onChange={onChange} {...item} />;
    }

    if (item.type === types.INTRO) {
        return (
            <Button
                text="Volgende"
                className="mt-m full"
                onClick={onChange}
            ></Button>
        );
    }

    return null;
}

export default Selectors;
