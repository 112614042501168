import "object.values";
import "core-js/stable";

import React from "react";
import { render } from "react-dom";

// Provider
import { Provider } from "react-redux";

// Styles
import "./styles/index.scss";

// Store
import { store } from "./core/store";

// Authentication
import { checkAuthenticationToken } from "./core/session";

// Components
import AuthenticationController from "./authentication-controller";
import Notification from "./views/components/notification";

const rootElement = document.getElementById("root");

const renderApp = (AuthenticationController) =>
    render(
        <Provider store={store}>
            <Notification />
            <AuthenticationController />
        </Provider>,
        rootElement
    );

checkAuthenticationToken(store.dispatch).then(() =>
    renderApp(AuthenticationController)
);
