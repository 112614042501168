import React, { useState, Fragment } from "react";

// Libraries
import { connect } from "react-redux";

// Core
import { createMeta } from "../../../core/session";
import Select from "react-select";

// Components
import Navigation from "../../components/navigation";
import Button from "../../components/buttons";
import { streets, arrangements, owners, ages, samenstellingen } from "./config";

const Form = ({ createMeta }) => {
    const [street, setStreet] = useState(null);
    const [age, setAge] = useState(null);
    const [samenstelling, setSamentStelling] = useState(null);

    const [arrangement, setArrangment] = useState(null);
    const [owner, setOwner] = useState(null);
    const [avg, setAvg] = useState(false);

    const submitForm = () => {
        createMeta({
            age,
            samenstelling,
            street,
            arrangement,
            owner,
            avg,
        });
    };

    return (
        <Fragment>
            <Navigation />
            <div className="flex flex-center">
                <div className="pt-l pb-l container container-m center relative">
                    <h3 className="pt-l fs-m a-l mb">
                        Aan welke straat woont u?
                    </h3>
                    <Select
                        options={streets}
                        onChange={({ value }) => setStreet(value)}
                    />
                    <h3 className="fs-m a-l mb mt">
                        In welke leeftijdscategorie valt u?
                    </h3>
                    <Select
                        options={ages}
                        blurInputOnSelect={true}
                        isSearchable={false}
                        onChange={({ value }) => setAge(value)}
                    />
                    <h3 className="fs-m a-l mb mt">
                        Wat is de samenstelling van uw huishouden?
                    </h3>
                    <Select
                        options={samenstellingen}
                        blurInputOnSelect={true}
                        isSearchable={false}
                        onChange={({ value }) => setSamentStelling(value)}
                    />

                    <h3 className="fs-m a-l mb mt">
                        Woont u in een huurwoning of een koopwoning?
                    </h3>
                    <Select
                        options={arrangements}
                        blurInputOnSelect={true}
                        isSearchable={false}
                        onChange={({ value }) => setArrangment(value)}
                    />
                    <h3 className="fs-m a-l mb mt">
                        Wie is de eigenaar van de woning?
                    </h3>
                    <Select
                        options={owners}
                        blurInputOnSelect={true}
                        isSearchable={false}
                        onChange={({ value }) => setOwner(value)}
                    />
                    <div className="flex flex-align mt-m">
                        <input
                            type="checkbox"
                            className="checkbox mr"
                            id="AVG"
                            name="AVG"
                            value={avg}
                            onChange={() => setAvg(!avg)}
                        ></input>
                        <label className="a-l" htmlFor="AVG">
                            Ik ga akkoord met de onderstaande voorwaarden
                        </label>
                    </div>
                    <div className="mt fs-xxs leading-tight a-l">
                        Ik geef toestemming voor het verwerken van de hiervoor
                        vermelde gegevens voor het uitvoeren en het maken van
                        een buurtagenda ten behoeve van de vitaliteitsagenda. Uw
                        gegevens zullen niet gebruikt worden voor andere
                        doeleinden. De gegevens worden vertrouwelijk gebruikt in
                        overeenstemming met de privacyverklaring van Eurus.
                    </div>
                    <Button
                        text={"Volgende"}
                        className="full mt-m mb-m"
                        disabled={!avg}
                        onClick={submitForm}
                    />
                </div>
            </div>
        </Fragment>
    );
};

export default connect(null, { createMeta })(Form);
