import React from "react";

function Container({ className = "", children = null }) {
    return (
        <div className={`box flex flex-center flex-align ${className}`}>
            <div className="pt-l pb-l container container-m center relative">
                {children}
            </div>
        </div>
    );
}

export default Container;
