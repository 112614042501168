export const messages = {
    ERROR_CREATE_SESSION:
        "Er is een fout opgetreden bij het starten van jouw sessie",

    ERROR_CREATE_ACCOUNT:
        "Er is een fout opgetreden bij het aanmaken van jouw account",

    ERROR_UPDATE: "Er is een fout opgetreden bij het aanpassen van de content",

    SUCCESS_UPDATE: "Content is succesvol aangepast",
    SUCCESS_SESSION: "Sessie succesvol gestart",
    GAME_COMPLETED: "Bedankt voor het meedoen.",
};
