export const streets = [
    { value: "Barnewinkel", label: "Barnewinkel" },
    { value: "Biezematen", label: "Biezematen" },
    { value: "Bronsgietsdronk", label: "Bronsgietsdronk" },
    { value: "Drapeniersdonk", label: "Drapeniersdonk" },
    { value: "Houtzagersdonk", label: "Houtzagersdonk" },
    { value: "Klingmakersdonk", label: "Klingmakersdonk" },
    { value: "Kloversdonk", label: "Kloversdonk" },
    { value: "Koperslagersdonk", label: "Koperslagersdonk" },
    { value: "Marskramersdonk", label: "Marskramersdonk" },
    { value: "Munsterdonk", label: "Munsterdonk" },
    { value: "Ploegersdonk", label: "Ploegersdonk" },
    { value: "Pottenbakkersdonk", label: "Pottenbakkersdonk" },
    { value: "Rademakersdonk", label: "Rademakersdonk" },
    { value: "Scheerdersdonk", label: "Scheerdersdonk" },
    { value: "Smeltersdonk", label: "Smeltersdonk" },
    { value: "Spinnersdonk", label: "Spinnersdonk" },
    { value: "Steenhouwersdonk", label: "Steenhouwersdonk" },
    { value: "Tichelaarsdonk", label: "Tichelaarsdonk" },
    { value: "Tingietersdonk", label: "Tingietersdonk" },
    { value: "Twijndersdonk", label: "Twijndersdonk" },
    { value: "Valkeniersdonk", label: "Valkeniersdonk" },
    { value: "Viltmakersdonk", label: "Viltmakersdonk" },
    { value: "Zadelmakersdonk", label: "Zadelmakersdonk" },
];

export const genders = [
    { value: "Man", label: "Man" },
    { value: "Vrouw", label: "Vrouw" },
    { value: "Anders", label: "Anders" },
];

export const arrangements = [
    { value: "Koop", label: "Koop" },
    { value: "Huur", label: "Huur" },
];

export const types = [
    {
        value: "Rijtjeswoning",
        label: "Rijtjeswoning",
    },
    {
        value: "Twee onder één kap",
        label: "Twee onder één kap",
    },
    {
        value: "Vrijstaande woning",
        label: "Vrijstaande woning",
    },
    {
        value: "Appartement/flat",
        label: "Appartement/flat",
    },
    {
        value: "Ouderen/senioren woning",
        label: "Ouderen/senioren woning",
    },
    {
        value: "Anders",
        label: "Anders",
    },
];

export const owners = [
    {
        value: "Ikzelf en/of mijn partner",
        label: "Ikzelf en/of mijn partner",
    },
    {
        value: "De woningbouwcoöperatie",
        label: "De woningbouwcoöperatie",
    },
    {
        value: "Anders",
        label: "Anders",
    },
];

export const ages = [
    { value: "17 of jonger", label: "17 of jonger" },
    { value: "18-20 jaar", label: "18-20 jaar" },
    { value: "21-29 jaar", label: "21-29 jaar" },
    { value: "30-39 jaar", label: "30-39 jaar" },
    { value: "40-49 jaar", label: "40-49 jaar" },
    { value: "50-59 jaar", label: "50-59 jaar" },
    { value: "60-69 jaar", label: "60-69 jaar" },
    { value: "70 of ouder", label: "70 of ouder" },
];

export const samenstellingen = [
    { value: "Eenpersoonshuishouden", label: "Eenpersoonshuishouden" },
    {
        value: "Meerpersoonshuishouden met kinderen",
        label: "Meerpersoonshuishouden met kinderen",
    },
    {
        value: "Meerpersoonshuishouden zonder kinderen",
        label: "Meerpersoonshuishouden zonder kinderen",
    },
];
