import { auth } from "../firebase";
import { setSessionListener } from "./actions";
// import { setUserListener } from "../login";
export * from "./actions";
export * from "./selectors";
export * from "./slice";

export const checkAuthenticationToken = (dispatch) =>
    new Promise((resolve, reject) => {
        const unsubscribe = auth.onAuthStateChanged(
            (user) => {
                user && dispatch(setSessionListener(user));
                // if (user) {
                //     console.log(user);
                //     if (user.isAnonymous) {
                //         dispatch(setSessionListener(user));
                //     } else {
                //         console.log("@setUserListener");
                //         dispatch(setUserListener(user));
                //     }
                // }
                unsubscribe();
                resolve();
            },
            (error) => reject(error)
        );
    });
