import React from "react";

// Libraries
import { connect } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
import { BrowserRouter as Router } from "react-router-dom";

// Core
import {
    getAuthenticated,
    getSessionLoaded,
    getSession,
    getSessionNotFound,
} from "./core/session";

// Components
import Login from "./views/login";
import UserNotFound from "./views/components/pages/user-not-found";
import PageLoading from "./views/components/pages/loading";
import App from "./views/app";

const AuthenticationController = ({
    authenticated,
    loaded,
    sessionNotFound,
}) => {
    // In case it happens that the session and db out out of sync
    if (sessionNotFound) {
        return <UserNotFound />;
    }

    if (authenticated && !loaded) {
        return <PageLoading />;
    }

    if (authenticated && loaded) {
        return <App />;
    }
    // Render the start screen
    return (
        <Router>
            <Login />
        </Router>
    );
};

const mapStateToProps = createSelector(
    getAuthenticated,
    getSessionLoaded,
    getSession,
    getSessionNotFound,
    (authenticated, loaded, session, sessionNotFound) => ({
        authenticated,
        loaded,
        session,
        sessionNotFound,
    })
);

export default connect(mapStateToProps, null)(AuthenticationController);
