import { config } from "react-spring";

export const ANIMATION_MODAL = {
    from: {
        opacity: 0,
        transform: "translate3d(0, 100%, 0)"
    },
    enter: {
        opacity: 1,
        transform: "translate3d(0, 0%, 0)"
    },
    leave: {
        opacity: 0,
        transform: "translate3d(0, 100%, 0)"
    },
    config: config.default
};
