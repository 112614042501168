import React from "react";

// Libraries
import { connect } from "react-redux";

// Core
import { signOut } from "../../../core/session";

// Components
import Container from "../container";
import Button from "../buttons";
import Image from "../images/image";

export default connect(null, { signOut })(({ signOut }) => (
    <Container>
        <Image
            height="250"
            src={`${process.env.PUBLIC_URL}/illustrations/empty.svg`}
        />
        <h2 className="title fw-b mt">Er is geen data gevonden</h2>
        <p className="fs-s mt mb">
            Dit is in the rare case that the session cookie en de db out of sync
            zijn, still we moeten de user de mogenlijk geven het opnieuw te
            proberen door de session te stoppen en te herstarten
        </p>
        <Button text="Sign out of session" className="full" onClick={signOut} />
    </Container>
));
