import React, { useState, useRef, Fragment } from "react";

// Libraires
import { animated, useTransition } from "react-spring";

function Option({ onConfirm, content, id, round, style }) {
    return (
        <animated.button
            style={style}
            onClick={() => onConfirm(id)}
            className="bg-1 card-competition  shadow-xl"
        >
            <div className="fs-m">{content}</div>
        </animated.button>
    );
}

function createPairs(list) {
    return list.reduce(function (result, _, index, array) {
        if (index % 2 === 0) result.push(array.slice(index, index + 2));
        return result;
    }, []);
}

function generateMap(list) {
    return list.reduce((acc, curr) => {
        acc[curr.id] = 0;

        return acc;
    }, {});
}

function CardCompetition({ style, questions, onComplete }) {
    const [winner, setWinner] = useState(null);

    const competition = useRef(generateMap(questions));
    const leavingId = useRef(null);
    const pairs = useRef(createPairs(questions));
    const round = useRef(1);
    const selectedList = useRef([]);

    const [currentPair, setCurrentPair] = useState(pairs.current[0]);

    const onConfirm = (id) => {
        const node = currentPair.find((item) => item.id === id);
        // add the node to the selected list (used to re-shuffel)
        selectedList.current.push(node);

        leavingId.current = id;

        // add points to the node in the competition
        competition.current[id] = competition.current[id] + 10;

        // remove the pair from the current pairs list
        pairs.current.shift();
        setCurrentPair([]);
        if (!pairs.current.length) {
            // no more pairs try and create more pairs
            pairs.current = createPairs(selectedList.current);
            round.current++;
            selectedList.current = [];

            if (pairs.current[0].length === 1) {
                setWinner(node);
                setTimeout(() => onComplete(competition.current), 3000);
            } else {
                // setCurrentPair(pairs.current[0]);
            }
        } else {
            // setCurrentPair(pairs.current[0]);
        }
    };

    const transitions = useTransition(currentPair, (item) => item.id, {
        from: {
            opacity: 0,
            transform: "scale(0.8) translate3d(0px,20%,0) rotate(0deg)",
        },
        enter: {
            opacity: 1,
            transform: "scale(1) translate3d(0px,0%,0) rotate(0deg)",
        },
        leave: (item) => {
            return item.id.toString() === leavingId.current.toString()
                ? {
                      opacity: 0,
                      transform:
                          "scale(0.8) translate3d(0px,0%,0) rotate(0deg)",
                  }
                : {
                      opacity: 0,
                      transform: `scale(1) translate3d(${
                          (200 + window.innerWidth) *
                          (Math.round(Math.random()) ? -1 : 1)
                      },0%,0) rotate(${
                          Math.round(Math.random()) ? -45 : 45
                      }deg)`,
                  };
        },
        trail: 75,
        onDestroyed: () => setCurrentPair(pairs.current[0]),
    });

    if (winner) {
        return (
            <Fragment>
                <h1 className="fs-l">En de winnaar is:</h1>

                <div className="card-question flex flex-center shadow-xl relative fs-l mt-m">
                    {winner.content}
                </div>
            </Fragment>
        );
    }

    return (
        <animated.div style={style}>
            <h1 className="fs-l">Selecteer het beste idee {round.current}/4</h1>
            <div className="relative">
                {transitions.map(({ key, item, props }) => (
                    <Option
                        key={key}
                        {...item}
                        style={props}
                        onConfirm={onConfirm}
                        round={round.current}
                    />
                ))}
            </div>
        </animated.div>
    );
}

export default CardCompetition;
