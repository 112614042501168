import React, { Fragment, useState } from "react";

// Components
import Button from "../../components/buttons";

function Open({ onChange }) {
    const [value, setValue] = useState("");
    const [knowMore, setKnowMore] = useState(false);

    const onSubmitNext = () => {
        onChange({
            value,
            knowMore,
        });
        setValue("");
    };

    return (
        <Fragment>
            <textarea
                className="textarea mt-m"
                placeholder="Aanvullingen/opmerkingen over dit thema"
                onChange={({ target }) => setValue(target.value)}
                value={value}
            />
            <div className="flex flex-align mt-m">
                <input
                    type="checkbox"
                    className="checkbox mr"
                    id="AVG"
                    name="AVG"
                    value={knowMore}
                    onChange={() => setKnowMore(!knowMore)}
                ></input>
                <label className="a-l" htmlFor="AVG">
                    Ik wil graag actief meedenken over dit thema
                </label>
            </div>
            <Button
                text="Volgende"
                className="mt-m full"
                onClick={onSubmitNext}
            ></Button>
        </Fragment>
    );
}

export default Open;
