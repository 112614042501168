import React from "react";

// - Libraries
import { useTransition, animated } from "react-spring";

// - Variables
import { ANIMATION_MODAL } from "./config";

// - Components
import ModalEventHandler from "./modal-event-handler";
import ModalNavigation from "./modal-navigation";

function Modal({ open, children, toggle, center = false }) {
    const transition = useTransition(open, ANIMATION_MODAL);

    return transition(
        (styles, item) =>
            item && (
                <animated.div
                    className={`modal ${
                        center && "flex flex-center flex-align"
                    }`}
                    style={styles}
                >
                    <ModalNavigation toggle={toggle} />

                    {children}
                    <ModalEventHandler toggle={toggle} />
                </animated.div>
            )
    );
}

export default Modal;
