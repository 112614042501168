export const Session = {
    id: null,

    questions: null,
    answers: [],

    meta: {},

    isAnonymous: true,

    gameCompleted: false,
    gameCompletedAt: null,

    formCompleted: false,
    formCompltedAt: null,
};
export const UserSession = {
    id: null,

    meta: {},

    isAnonymous: false,
};

export const Question = {
    id: null,
    type: null,
};

export const unwrapSnapshot = (payload) => {
    return {
        ...payload.data(),
        id: payload.id,
    };
};

export const unwrapList = (payload, Model) => {
    let list = [];

    payload.forEach((item) => {
        list[list.length] = unwrapSnapshot(item, Model);
    });

    return list;
};

export class CollectionListener {
    on(query, dispatch, action) {
        const unsubscribe = query.onSnapshot((payload) =>
            dispatch(action(unwrapList(payload)))
        );

        this._unsubscribe = () => unsubscribe();
    }

    off() {
        this._unsubscribe();
    }
}

export class DocumentListener {
    on(query, Model, dispatch, action, errorAction = null) {
        const unsubscribe = query.onSnapshot((payload) =>
            payload.exists
                ? dispatch(action(unwrapSnapshot(payload, Model)))
                : errorAction && dispatch(errorAction())
        );

        this._unsubscribe = () => unsubscribe();
    }

    off() {
        this._unsubscribe();
    }
}
